import React from 'react';
import { bool, arrayOf, object } from 'prop-types';
import PopularItem from '../PopularItem';
import CSS from './style.scss';

export default function PopularItemsList({ popularItems, disabled }) {
  return (
    <ul className={CSS.popularItemsList} data-testid="popular-items-list">
      {popularItems.slice(0, 3).map(item => (
        <PopularItem key={`popular-${item.id}`} item={item} disabled={disabled} />
      ))}
    </ul>
  );
}

PopularItemsList.propTypes = {
  disabled: bool,
  popularItems: arrayOf(object).isRequired,
};

PopularItemsList.defaultProps = {
  disabled: false,
};
