import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Tile from 'components/Tile';
import PopularInIssue from 'modules/issue/components/PopularItemsInIssue';
import { providerById, prefillSelector } from 'selectors/providers';

function PopularInIssueTile(props) {
  return (
    <Tile layout="popular-in-issue" {...props}>
      <PopularInIssue
        issue={props.issue}
        provider={prefillSelector(providerById)(props.providerId)}
        popularItems={props.items}
      />
    </Tile>
  );
}

PopularInIssueTile.propTypes = {
  providerId: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  issue: PropTypes.object.isRequired,
};

export default PopularInIssueTile;
