/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TilePane from 'components/TilePane';
import PageTile from 'components/tiles/PageTile';
import Measure from 'react-measure';
import BrowserEnvironment from 'instances/browser_environment';
import PopularInIssueTile from 'components/tiles/PopularInIssueTile';
import IssueAcquisitionContainer from 'modules/issue/containers/IssueAcquisitionContainer';

class PagesIssue extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paneHeight: null,
    };
  }

  componentDidUpdate(prevProps) {
    const { navigateToSectionIndex } = this.props;

    if (prevProps.navigateToSectionIndex !== navigateToSectionIndex) {
      this.list.scrollTo(navigateToSectionIndex, true);
    }
  }

  onMeasure = ({ client }) => {
    const { height } = client;

    if (height > 0) {
      this.setState({
        paneHeight: height,
      });
    }
  };

  renderPopular() {
    const { issue, popularItems } = this.props;

    return (
      <PopularInIssueTile
        key={`popular-in-issue-${issue.id}`}
        providerId={issue.get('provider').id}
        items={popularItems}
        issue={issue}
      />
    );
  }

  renderArticles() {
    const { pages, issue } = this.props;

    // eslint-disable-next-line react/no-array-index-key
    return pages.map((page, index) => <PageTile key={`${issue.id}_page_${index}`} page={page} />);
  }

  renderTiles() {
    const { loading } = this.props;
    const { paneHeight } = this.state;

    if (loading || paneHeight === null) {
      return null;
    }

    return [this.renderPopular(), ...this.renderArticles()];
  }

  renderMobileNavigation() {
    const { mobileNavigation } = this.props;

    if (BrowserEnvironment.isMobile()) {
      return mobileNavigation;
    }

    return null;
  }

  render() {
    const { disabled, loading, navigateToSectionIndex, onNearEnd, onScroll, issue } = this.props;

    return (
      <div>
        <Measure client onResize={this.onMeasure}>
          {({ measureRef }) => (
            <TilePane
              ref={c => {
                this.list = c;
              }} // eslint-disable-line brace-style
              innerRef={measureRef}
              showButtons={!!BrowserEnvironment.isDesktop()}
              active={!disabled}
              loading={loading}
              orientation="horizontal"
              initialIndex={navigateToSectionIndex}
              onNearEnd={onNearEnd}
              onScroll={onScroll}
            >
              {BrowserEnvironment.isMobile() ? (
                <IssueAcquisitionContainer issue={issue} />
              ) : (
                undefined
              )}
              {this.renderMobileNavigation()}
              {this.renderTiles()}
            </TilePane>
          )}
        </Measure>
        {!BrowserEnvironment.isMobile() ? <IssueAcquisitionContainer issue={issue} /> : undefined}
      </div>
    );
  }
}

PagesIssue.propTypes = {
  issue: PropTypes.object,
  onScroll: PropTypes.func.isRequired,
  onNearEnd: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  navigateToSectionIndex: PropTypes.number.isRequired,
  disabled: PropTypes.bool.isRequired,
  mobileNavigation: PropTypes.any.isRequired,
  pages: PropTypes.array.isRequired,
  popularItems: PropTypes.array.isRequired,
};

export default PagesIssue;
