import { branch, renderNothing, compose } from '@blendle/recompose';
import withCapabilityIsLocked from './withCapabilityIsLocked';

const renderNothingIfCapabilityIsLocked = (capability) => (ComposedComponent) =>
  compose(
    withCapabilityIsLocked(capability),
    branch(({ capabilityIsLocked }) => capabilityIsLocked, renderNothing),
  )(ComposedComponent);

export default renderNothingIfCapabilityIsLocked;
