import React from 'react';
import { string } from 'prop-types';
import { FEATURE_CAPABILITY_SUBSCRIPTION_CONFIGURATION } from 'app-constants';
import { translate } from 'instances/i18n';
import Link from 'components/Link';
import renderNothingIfCapabilityIsLocked from 'higher-order-components/renderNothingIfCapabilityIsLocked';

function ConnectSubscription({ providerId }) {
  return (
    <div className="bottom-content">
      <h3>{translate('issue.popular.subscribed')}</h3>
      <p>
        <Link href={`/settings/subscriptions/${providerId}`}>
          {translate('issue.popular.subscription')}
        </Link>
      </p>
    </div>
  );
}

ConnectSubscription.propTypes = {
  providerId: string.isRequired,
};

export const ConnectSubscriptionComponent = ConnectSubscription;
export default renderNothingIfCapabilityIsLocked(FEATURE_CAPABILITY_SUBSCRIPTION_CONFIGURATION)(
  ConnectSubscription,
);
