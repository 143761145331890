import React from 'react';
import { string, number } from 'prop-types';
import Tile from '../Tile';
import Itemtile from './ItemTile';

export default function WebIssueTile({ itemId, tileHeight, internalLocation }) {
  return (
    <Tile type="item">
      <Itemtile
        itemId={itemId}
        analytics={{ internal_location: internalLocation }}
        tileHeight={tileHeight}
        toastDisabled
      />
    </Tile>
  );
}

WebIssueTile.propTypes = {
  itemId: string.isRequired,
  tileHeight: number.isRequired,
  internalLocation: string,
};

WebIssueTile.defaultProps = {
  internalLocation: 'issue',
};
