import React from 'react';
import PropTypes from 'prop-types';
import Dialogue from 'components/dialogues/Dialogue';
import { translateElement } from 'instances/i18n';
import formatCurrency from 'helpers/formatCurrency';
import IssueStack from 'containers/IssueStackContainer';

function ConfirmIssueAcquireDialog(props) {
  const { onConfirm, onCancel, providerName, dateString, coverUrl, price } = props;

  return (
    <Dialogue className="v-acquire-issue" onClose={onCancel}>
      <IssueStack className="issue-stack" src={coverUrl} />
      {translateElement(<h2 />, 'item.dialogs.acquire.title_not_acquired', [
        providerName,
        dateString,
      ])}
      {translateElement(
        <div className="btn btn-green btn-fullwidth" onClick={onConfirm} />,
        'item.dialogs.acquire.btn_not_acquired',
        [formatCurrency(price)],
      )}
    </Dialogue>
  );
}

ConfirmIssueAcquireDialog.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  providerName: PropTypes.string.isRequired,
  dateString: PropTypes.string.isRequired,
  coverUrl: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
};

export default ConfirmIssueAcquireDialog;
