import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { find } from 'lodash';
import moment from 'moment';
import SubscriptionsManager from 'managers/subscriptions';
import { translate, translateElement } from 'instances/i18n';
import { getException } from 'helpers/countryExceptions';
import Link from 'components/Link';
import { getIssueDateString } from 'helpers/dateString';
import ConnectSubscription from './ConnectSubscription';
import PopularItemsList from './PopularItemsList';

class PopularItemsInIssue extends Component {
  static propTypes = {
    provider: PropTypes.object.isRequired,
    issue: PropTypes.object,
    disabled: PropTypes.bool,
    popularItems: PropTypes.array.isRequired,
  };

  _renderSubscription() {
    const canSubscribe = find(SubscriptionsManager.getProviders(), {
      id: this.props.provider.id,
    });
    const acquisition = this.props.issue.getEmbedded('b:issue-acquisition');

    if (
      getException('hideSubscriptionsSetting', false) ||
      !canSubscribe ||
      (acquisition && !acquisition.get('subscription'))
    ) {
      return null;
    }

    if (this.props.popularItems.length === 0) {
      return null;
    }

    const providerName = this.props.provider.name;

    if (acquisition && acquisition.get('subscription')) {
      return (
        <div className="bottom-content">
          <div className="is-subscribed">
            <h3>{translate('issue.popular.is_subscribed_header')}</h3>
            <p className="subscribed">
              <span className="subscriber">{translate('issue.popular.subscriber')}</span>
              {translateElement('issue.popular.is_subscribed_content', [providerName])}
              <span className="edit-subscription">
                <Link href="/settings/subscriptions">({translate('issue.popular.edit')})</Link>
              </span>
            </p>
          </div>
        </div>
      );
    }

    return <ConnectSubscription providerId={this.props.provider.id} />;
  }

  render() {
    const { issue, provider, disabled, popularItems } = this.props;
    const issueAcquisition = issue.getEmbedded('b:issue-acquisition');
    const providerName = provider.name;
    const className = classNames([
      'v-popular-in-issue',
      'tile-explain',
      {
        'l-acquire-issue': issueAcquisition && issueAcquisition.isEligibleForAcquisition(),
      },
    ]);

    return (
      <div className={className}>
        <div className="explanation">
          <h2>
            {translateElement('issue.popular.title')}
            {getIssueDateString(moment(issue.get('date')), providerName)}
          </h2>
        </div>
        <PopularItemsList popularItems={popularItems} disabled={disabled} />
        {this._renderSubscription()}
      </div>
    );
  }
}

export default PopularItemsInIssue;
